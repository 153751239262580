// DataContext.js
import React, { createContext, useContext, useState } from 'react';

// Create the context
const DataContext = createContext();

// Custom hook to use the context
export const useData = () => useContext(DataContext);

// Provider component that wraps the app
export const DataProvider = ({ children }) => {
  const [companiesData, setCompaniesData] = useState(null); // State for storing companies data
  const [userData, setUserData] = useState(null);

  // Function to store companiesData
  const storeCompaniesData = (data) => {
    setCompaniesData(data);
  };

  const saveUserData = (data) => {
    setUserData(data);
  };

  const clearUserData = () => {
    setUserData(null);
  };
  
  return (
    <DataContext.Provider value={{ companiesData, storeCompaniesData, userData, saveUserData, clearUserData }}>
      {children}
    </DataContext.Provider>
  );
};
