import React, { useEffect, useState } from 'react';
import {OnboardingHeaderPart} from '../../header/OnboardingHeader';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { FiMapPin } from 'react-icons/fi';

const OnboardingCampaignMatching = () => {
    const navigate = useNavigate();
    //
    const [isLoading, setIsLoading] = useState(false);
    //
     
    const WelcomeString = () => {
        return (
        <div>
            <h1 className="text-4xl font-bold text-center mb-3">
            Campaign Information
            </h1>
            <div className="flex items-center justify-center text-lg">
                <FiMapPin className="text-blue-500 mr-2" />
                Location: {localStorage.getItem('location')}
            </div>
        </div>
        );
    };

    

    const TargetIndustry = () => {
        // if (!userData) return null;
        const industry = localStorage.getItem("industry");
        let industries = [];

        try {
            // Attempt to parse the industry field as JSON
            industries = industry ? JSON.parse(industry) : [];
          } catch {
            // If parsing fails, assume it's a single string
            industries = industry ? [industry] : [];
        }

        return (
            <div className="p-3 w-full bg-white flex flex-col space-y-4 rounded-xl shadow-md items-center">
              {/* Title */}
              <div className="text-center text-lg font-bold">TARGET INDUSTRY</div>
        
              {/* Industry list container */}
              <div className="p-2 w-[90%] h-[280px] bg-white rounded-lg">
                <div className= "full flex flex-wrap gap-2 overflow-auto">
                    {industries.map((item, index) => (
                    <div
                        key={index}
                        className="bg-gray-100 px-3 py-2 rounded-lg shadow-md whitespace-nowrap h-[30px] flex items-center"
                    >
                        <strong>{item}</strong>
                    </div>
                    ))}
                    {industries.length === 0 && (
                    <div className="text-gray-500">No target industries specified</div>
                    )}
                </div>
              </div>
            </div>
          );
        };

    const TargetClient = () => {

        const result = [
            {
              company_name: "Velora",
              business_size: "1-50 Employee",
              pain_point: "Need for Streamlined Operations, Task Automation and Sale Tracking",
              goal: "Boost Efficiency, Reduce Operational Costs, And Improve Customer Experience",
            },
            {
              company_name: "InnovaTech",
              business_size: "51-200 Employees",
              pain_point: "Difficulty Scaling Projects and Managing Resources Effectively",
              goal: "Enhance Scalability and Optimize Team Collaboration",
            },
            {
              company_name: "Greenleaf Enterprises",
              business_size: "201-500 Employees",
              pain_point: "Manual Processes Slowing Down Productivity",
              goal: "Automate Key Workflows and Drive Operational Excellence",
            },
          ];

        const ContentBox = ({ title, content }) => {
            return (
              <div className="p-4 w-[250px] min-w-[250px] min-h-[280px] bg-gray-100 rounded-lg">
                <div className="text-blue-800 text-xl font-bold mb-2">{title}</div>
                {/* Add bullets in front of each line */}
                <ul className="list-disc pl-4">
                    {content.split("\n").map((line, index) => (
                        <li key={index} className="text-md mb-1">
                        {line}
                        </li>
                    ))}
                </ul>
              </div>
            );
          };

        return (
        <div className="p-3 bg-white flex flex-col space-y-4 rounded-xl h-full items-center shadow-md ">
            {/* Title */}
            <div className="text-center text-lg font-bold">TARGET CLIENTS</div>
            
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 md:gap-2 lg:gap-4 p-2">
                {result.map((item, index) => (
                    <ContentBox
                    key={index}
                    title={item['company_name']}
                    content={`Business Size: ${item.business_size}\nPain Point: ${item.pain_point}\nGoal: ${item.goal}`}
                    />
                ))}
            </div>

        </div>
        );
    };

    const ProfilesPart = () => {
        return (
          <div className="w-full max-w-[1300px] flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
            <div className="w-full md:w-1/3 min-h-[400px]">
              <TargetIndustry />
            </div>
            <div className="w-full md:w-2/3 min-h-[400px]">
              <TargetClient />
            </div>
          </div>
        );
    };

    const UserInfomationBoard = () => {
        return (
            <div className="flex-grow flex flex-col items-center space-y-8 py-4 px-4 md:px-8">
                {/* First Part */}
                <WelcomeString />
                {/* Second Part */}
                <ProfilesPart />
            </div>
        );
    };

    const Footer = () => {
        return (
        <div className='py-4 bg-white flex justify-end shadow-2xl'>
            <div className="mb-3 mt-3">
                <button 
                className="bg-[#223F9E] text-white font-semibold py-2 px-10 mr-[50px] rounded-full"
                onClick={handleNextClick}
                >
                Next
                </button>
            </div>
        </div>
        )
    }


    const handleNextClick = () => {
        // Add your logic here for what should happen when the Next button is clicked
        console.log("Next button clicked");
        // For example, you might want to navigate to the next page or submit the review
        // You can add navigation logic or API calls here
        navigate('/reviewinfo');
    };

    return (

    <div>
    {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-6 rounded shadow-lg">
            <div className="flex items-center space-x-2">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
            <span className="text-lg font-medium">Loading, please wait...</span>
            </div>
        </div>
        </div>
    )}
    {/* Render user data or other components here */}
        <div className="flex flex-col min-h-screen w-full bg-gray-100">
            {/* This part is for the header section */}
            <OnboardingHeaderPart />
            {/* This part is for the main/body section */}
            <UserInfomationBoard />
            {/* Footer is placed here to ensure it stays at the bottom */}
            <Footer />
        </div>
    </div>   

  );
};

export default OnboardingCampaignMatching;
