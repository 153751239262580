import React, { useEffect } from 'react';
import {OnboardingHeaderPart, LargeImagePlaceholder} from '../../header/OnboardingHeader';
import { useNavigate } from 'react-router-dom';
import video3 from '../../assets/page3.mp4'

const CampaignInitialize = () => {
    
  useEffect(() => {
    localStorage.removeItem('userType');
  }, []);

  const navigate = useNavigate();

  const Button = ({ className, onClick, text }) => (
    <button className={className} onClick={onClick}>
      {text}
    </button>
  );

  const HandleUsingCampaignAssistance = () => {
    console.log("Using Campaign Assistance");
    navigate('/useraichat')
    // Add your logic here
  };

  const HandleUsingManualFill = () => {
    console.log("Using Manual Fill");
    navigate('/campfill')
    // Add your logic here
  };

  const HandleSkip  = () => {
    console.log("Skipped");
    navigate('/onboarding-plan')
    // Add your logic here
  };

  const PlanOption = ({ title, features, onChoose }) => (
    <div className="bg-white shadow-md rounded-lg p-4 flex flex-col items-start w-116 h-30 justify-between relative">
      {/* Center-Aligned Title */}
      <div className="flex justify-center w-full mb-4">
        <h3 className="text-lg font-semibold text-center">{title}</h3>
      </div>
      {/* Features List */}
      <div className="flex justify-center items-center h-[150px]">
        <ul className="list-disc list-inside mb-2 flex-grow text-left w-full ml-2">
          {features.map((feature, index) => (
            <li key={index} className="text-black-700">{feature}</li>
          ))}
        </ul>
      </div>
      {/* Center-Aligned Choose Button */}
      <div className="flex justify-center w-full mt-4">
        <Button
          onClick={onChoose}
          className="text-white bg-[#223F9E] min-w-[100px] py-2 px-4 font-semibold rounded-full"
          text="Choose"
        />
      </div>
    </div>
  );

  const SelectionBox = () => {
    const plans = [
      { 
        title: "Using Campaign Assistant", 
        features: ["Our AI Campagin Assistant will help you to create new campaign by complete each field we need to collect."],
        onChoose: HandleUsingCampaignAssistance
      },
      { 
        title: "Manual Fill", 
        features: ["Providing information about your campaign and our AI will help you to complete the total campaign"],
        onChoose: HandleUsingManualFill
      },
    ];

    return (
      <main className="max-h-[600px] flex-grow flex flex-col justify-center rounded-lg items-start p-6 bg-gray-100 shadow-md">
        <h2 className="text-xl font-bold mb-6">Tell us about your situation</h2>
        <div className="min-h-[350px] text-sm flex space-x-4 mb-8 ">
          {plans.map((plan, index) => (
            <PlanOption key={index} title={plan.title} features={plan.features} onChoose={plan.onChoose} />
          ))}
        </div>
        {/* Right-Aligned Skip Button */}
        <div className="flex justify-end w-full">
          <Button
            onClick={HandleSkip} // Note: Replace with actual navigation logic
            className="h-[40px] min-w-[150px] text-blue-700 bg-white py-1 px-7 rounded-lg border border-blue-700"
            text="Skip"
          />
        </div>
      </main>
    );
  };

  return (
    <div className="flex flex-col min-h-screen bg-white relative">
      {/*This part is for the header section */}
      <OnboardingHeaderPart />
      {/*This part is for the main/body section */}
      <div className="flex justify-center items-start flex-grow pt-10 overflow-auto bg-gray-100">
        <div className="flex flex-col lg:flex-row w-full max-w-[1200px] min-h-[600px] md:justify-center md:items-center">
          {/* The below <div> is the left part */}
          {/* <div className="w-[600px] flex flex-col items-start justify-start ml-16 mr-10">
            <div className="flex-grow flex items-center justify-center bg-gray-200 w-full rounded-xl h-[calc(100%-1rem)]">
              <span className="text-gray-500">Large Image Placeholder</span>
            </div>
          </div> */}
          <LargeImagePlaceholder videomain={video3}/>
          {/* The below <div> is the right part*/}
          <div className="md:w-[600px] md:h-[600px] bg-gray-100 flex flex-col justify-center items-center"><SelectionBox /></div>
        </div>
      </div>
    </div>
  );
};

export default CampaignInitialize;
