import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import SharedAuthUI from './SharedAuthUI';
import { useAuth } from '../../authorize/AuthContext';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const { login } = useAuth();
    const roboman_api = process.env.REACT_APP_ROBOMAN_API;

    useEffect(() => {
        const loggedIn = localStorage.getItem('isLoggedIn');
        if (loggedIn === 'true') {
            navigate('/dashboard');
        }
    }, [navigate]);

    const handleEmailChange = (event) => {
        setUsername(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleLogin = async (event) => {
        event.preventDefault();
        if (!username || !password) {
          Swal.fire({
            title: 'Error!',
            text: 'Please input your username and password',
            icon: 'error',
            confirmButtonColor: "#223F9E",
            confirmButtonText: 'Back'
          });
          return;
        }
    
        try {
          const response = await fetch(`${roboman_api}/login`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email: username,
              password: password,
            }),
          });
    
          if (response.ok) {
            const data = await response.json();
            Swal.fire({
              title: 'Login',
              text: "Login Successful",
              icon: 'success',
              confirmButtonColor: "#223F9E",
              confirmButtonText: 'Close'
            }).then((result) => {
              if (result.isConfirmed) {
                login(); // Update auth state
                navigate('/dashboard');
              }
            });
            // Save the access_token to local storage
            localStorage.setItem('access_token', data.access_token);
          } else {
            const errorData = await response.json();
            let messages = [];
    
            if (Array.isArray(errorData.detail)) {
              messages = errorData.detail.map((item) => item.msg);
              Swal.fire({
                title: 'Error!',
                text: messages.join(', '),
                icon: 'error',
                confirmButtonColor: "#223F9E",
                confirmButtonText: 'Close'
              });
            } else {
              Swal.fire({
                title: 'Error!',
                text: errorData.detail,
                icon: 'error',
                confirmButtonColor: "#223F9E",
                confirmButtonText: 'Close'
              });
            }
          }
        } catch (error) {
          console.error('Error:', error);
          Swal.fire({
            title: 'Error!',
            text: 'An error occurred. Please try again.',
            icon: 'error',
            confirmButtonColor: "#223F9E",
            confirmButtonText: 'Back'
          });
        }
      };
    

    return (
        <SharedAuthUI>
            <div className="w-1/2 p-10 mt-8">
                <h2 className="font-bold text-2xl mb-6">Let's sign you in.</h2>
                <form onSubmit={handleLogin} className="space-y-4">
                    <input
                        type="email"
                        placeholder="Email"
                        value={username}
                        onChange={handleEmailChange}
                        className="w-full p-3 rounded bg-gray-100 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={handlePasswordChange}
                        className="w-full p-3 rounded bg-gray-100 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required
                    />
                    <Link to="/forgotpw" className="text-sm text-blue-600 text-left hover:underline block mt-2">Forgot password?</Link>
                    <button 
                        type="submit" 
                        className="w-full py-3 bg-blue-600 text-white rounded hover:bg-blue-700 transition duration-300"
                        onClick={handleLogin}
                    >
                        Sign in
                    </button>
                </form>
                <p className="text-sm text-center my-4">Or Sign in with Linkedin</p>
                <button className="w-full py-3 border border-blue-600 text-blue-600 rounded hover:bg-blue-50 transition duration-300">
                    Linkedin
                </button>
                <p className="text-sm text-center mt-4">
                    Don't have an account yet? <Link to="/signup" className="text-blue-600 hover:underline">Sign Up</Link>
                </p>
            </div>
        </SharedAuthUI>
    );
}

export default Login;
