import React from 'react';
import Logo from '../assets/Logo.png';
import manimage from '../assets/image1.png';
import { useNavigate } from 'react-router-dom';
import VideoPlayer from '../component/onboardingPage/VideoPlayer';
import videonothing from '../assets/saynothing.mp4'

const LargeImagePlaceholder = ({videomain}) => {
    return (
      <div className="w-[600px] h-[600px] bg-[#223F90] flex items-center justify-center lg:ml-16 lg:mr-4 mb-3 pb-4 rounded-md overflow-hidden">
        {/* <img 
          src={manimage} 
          alt="Man" 
          className="w-full h-full object-cover transform scale-105"
        /> */}
        <VideoPlayer video1Url={videomain} sayNothingUrl={videonothing} />
      </div>
    );
};

const OnboardingHeaderPart = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    // Handle the logout logic here, e.g., clear tokens, redirect, etc.
    console.log("User logged out");
    localStorage.clear();
    navigate('/login');

  };

  return (
    <>
      {/* Full-width header box containing both image placeholders */}
      <div className="w-full bg-gray-100 py-5 flex items-center">
        {/* Logo Section */}
        <div className="w-40 h-20 bg-transparent flex items-center justify-center text-xs ml-[10%]">
          <img src={Logo} alt="Logo" className="max-w-full max-h-full object-contain" />
        </div>
        {/* Spacer to push the button to the right */}
        <div className="flex-grow"></div>
        {/* Logout button */}
        <button
          onClick={handleLogout}
          className="ml-auto mr-[100px] py-2 px-4 bg-[#223F9E] text-white rounded-full hover:bg-[#223F9E] transition"
        >Logout
        </button>
      </div>
    </>
  );
};

// Export LargeImagePlaceholder as a named export
export { OnboardingHeaderPart, LargeImagePlaceholder };
