// VideoPlayer.js
import React, { useState, useRef, useEffect } from 'react';
import { FaVolumeUp, FaVolumeMute, FaPlay, FaPause, FaRedo } from "react-icons/fa";

const VideoPlayer = ({ video1Url, sayNothingUrl }) => {
  const [currentVideo, setCurrentVideo] = useState(video1Url);
  const [isVideo1, setIsVideo1] = useState(true);
  const [error, setError] = useState(null);
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);

  const zoomFactor = 1.1;

  useEffect(() => {
    const handleCanPlay = () => {
      if (videoRef.current) {
        videoRef.current.play().catch(e => {
          console.error("Error playing video:", e);
          // setError("Error playing video. Click to try again.");
        });
      }
    };

    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener('canplay', handleCanPlay);
      videoElement.load();
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('canplay', handleCanPlay);
      }
    };
  }, [currentVideo]);

  const handleVideoEnd = () => {
    console.log("Video ended");
    if (isVideo1) {
      setCurrentVideo(sayNothingUrl);
      setIsVideo1(false);
    }
  };

  const handleRepeat = () => {
    console.log("Repeat button clicked");
    setCurrentVideo(video1Url);
    setIsVideo1(true);
    setError(null);
  };

  const handleMuteToggle = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setIsMuted(videoRef.current.muted);
    }
  };

  const handlePlayPauseToggle = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsPlaying(true);
      } else {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    }
  };

  const handleCanPlay = () => {
    console.log("Video can play");
  };

  const handleError = (e) => {
    console.error("Video error:", e);
    setError("Error loading video. Please check the video source.");
  };

  return (
    <div className="relative w-full max-w-4xl mx-auto mt-5">
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
          <span className="block sm:inline">{error}</span>
        </div>
      )}
      <video
        ref={videoRef}
        className="w-full h-auto"
        onEnded={handleVideoEnd}
        onCanPlay={handleCanPlay}
        onError={handleError}
        style={{
            transform: `scale(${zoomFactor})`,
            transformOrigin: 'center center'
        }}
        autoPlay
        playsInline
        // controls // Add controls for debugging
        loop={!isVideo1}
      >
        <source src={currentVideo} type="video/ogg" />
        Your browser does not support the video tag.
      </video>

      {/* Repeat Button */}
      <button
        className="absolute bottom-2 left-4 bg-black bg-opacity-50 text-white px-3 py-1 rounded-full hover:bg-opacity-75 transition-colors duration-200"
        onClick={handleRepeat}
      >
        <FaRedo size={24} />
      </button>

      {/* Mute/Unmute Button */}
      <button
        className="absolute bottom-2 right-20 bg-black bg-opacity-50 text-white px-3 py-1 rounded-full hover:bg-opacity-75 transition-colors duration-200"
        onClick={handleMuteToggle}
      >
        {isMuted ? <FaVolumeMute size={24} /> : <FaVolumeUp size={24} />}
      </button>

      {/* Play/Pause Button */}
      <button
        className="absolute bottom-2 right-4 bg-black bg-opacity-50 text-white px-3 py-1 rounded-full hover:bg-opacity-75 transition-colors duration-200"
        onClick={handlePlayPauseToggle}
      >
        {isPlaying ? <FaPause size={24} /> : <FaPlay size={24} />}
      </button>
      {/* <p className="mt-2">Current video: {currentVideo}</p> */}
    </div>
  );
};

export default VideoPlayer;