import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import '../../../src/App.css';
import SharedAuthUI from './SharedAuthUI';
import Swal from 'sweetalert2';

const Signup = () => {
    const [email, setEmail] = useState('');
    const [createPassword, setCreatePassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const roboman_api = process.env.REACT_APP_ROBOMAN_API;

    // useEffect(() => {
    //     const loggedIn = localStorage.getItem('isLoggedIn');
    //     if (loggedIn === 'true') {
    //         navigate('/onboarding-select');
    //     }
    // }, [navigate]);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleCreatePasswordChange = (event) => {
        setCreatePassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    const handleSignup = async () => {
        if (createPassword !== confirmPassword) {
            // setError('Passwords do not match');
            Swal.fire({
                title: 'Error!',
                text: 'Passwords do not match',
                icon: 'error',
                confirmButtonColor: "#223F9E",
                confirmButtonText: 'OK'
            });
            return;
        }

        try {
            const response = await fetch(`${roboman_api}/signup`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    password: createPassword,
                }),
            });

            if (response.ok) {
                const data = await response.json();
                Swal.fire({
                    title: 'Success!',
                    text: data.message + " You'll now be redirected to choose your account type.",
                    icon: 'success',
                    confirmButtonColor: "#223F9E",
                    confirmButtonText: 'OK'
                }).then((result) => {
                    if (result.isConfirmed) {
                        localStorage.setItem('isLoggedIn', 'true');
                        localStorage.setItem('userEmail', email); // Save email to local storage
                        loginAfterSignup();  
                    }
                      
                });
            } else {
                const errorData = await response.json();
                setError(errorData.detail);
                Swal.fire({
                    title: 'Error!',
                    text: errorData.detail,
                    icon: 'error',
                    confirmButtonColor: "#223F9E",
                    confirmButtonText: 'OK'
                });
            }
        } catch (error) {
            console.error('Error:', error);
            setError('An error occurred. Please try again.');
            Swal.fire({
                title: 'Error!',
                text: 'An error occurred. Please try again.',
                icon: 'error',
                confirmButtonColor: "#223F9E",
                confirmButtonText: 'OK'
            });
        }
    };

    const loginAfterSignup = async () => {
        try {
            const loginResponse = await fetch(`${roboman_api}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    password: createPassword,
                }),
            });

            if (loginResponse.ok) {
                const loginData = await loginResponse.json();
                console.log(loginData.message);
                localStorage.setItem('isLoggedIn', 'true');
                        // Save the access_token to local storage
                localStorage.setItem('access_token', loginData.access_token);
                navigate('/dashboard');
            } else {
                const errorData = await loginResponse.json();
                Swal.fire({
                    title: 'Error!',
                    text: errorData.detail,
                    icon: 'error',
                    confirmButtonColor: "#223F9E",
                    confirmButtonText: 'OK'
                });
            }
        } catch (error) {
            console.error('Error:', error);
            Swal.fire({
                title: 'Error!',
                text: 'An error occurred during login. Please try again.',
                icon: 'error',
                confirmButtonColor: "#223F9E",
                confirmButtonText: 'OK'
            });
        }
    };

    return (
        <SharedAuthUI>
            <div className="w-1/2 p-10 mt-8">
                <h2 className="font-bold text-2xl mb-6">Sign up</h2>
                <form onSubmit={(e) => { e.preventDefault(); handleSignup(); }} className="space-y-6">
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={handleEmailChange}
                        className="w-full px-4 py-3 rounded-md bg-gray-100 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required
                    />
                    <input
                        type="password"
                        placeholder="Create Password"
                        value={createPassword}
                        onChange={handleCreatePasswordChange}
                        className="w-full px-4 py-3 rounded-md bg-gray-100 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required
                    />
                    <input
                        type="password"
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        className="w-full px-4 py-3 rounded-md bg-gray-100 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required
                    />
                    {error && <p className="text-red-500 text-sm">{error}</p>}
                    <button type="submit" className="w-full bg-blue-600 text-white py-3 rounded-md hover:bg-blue-700 transition duration-300">Sign up</button>
                </form>
                <p className="text-center text-sm text-gray-600 mt-6">Or Sign up with Linkedin</p>
                <button className="w-full mt-4 bg-white text-blue-600 py-3 border border-blue-600 rounded-md hover:bg-blue-50 transition duration-300">Linkedin</button>
                <p className="text-center text-sm text-gray-600 mt-6">
                    Already have an account? <Link to="/login" className="text-blue-600 hover:underline">Sign In</Link>
                </p>
            </div>
        </SharedAuthUI>
    );
}

export default Signup;
