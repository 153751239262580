// src/App.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import HeaderMainPage from '../../header/HeaderMainPage';
import Swal from 'sweetalert2';
import { FaQuestionCircle } from 'react-icons/fa';

const InformationContainer = () => {
  const navigate = useNavigate();
  // const location = useLocation();
  const [CoreProduct, setCoreProduct] = useState('');
  const [Benefit, setBenefit] = useState('');
  const [TargetAudience, setTargetAudience] = useState('');
  const [IdealClient, setIdealClient] = useState('');
  const [UniqueSellingPoint, setUniqueSellingPoint] = useState('');
  const roboman_api = process.env.REACT_APP_ROBOMAN_API;

  const extractCampaignInfo = async (text) => {
    try {
        const response = await fetch(`${roboman_api}/llm/campaign/parser`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('access_token'),
            },
            body: JSON.stringify({
                input_str: JSON.stringify(text)
            }),
        });

        if (!response.ok) {
            console.error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        
        if (data) {
            console.log('parsing data successfull');
            localStorage.setItem('campaign_name', data.campaign_name);
            localStorage.setItem('core_service', data.core_service);
            localStorage.setItem('unique_selling_proposition', data.unique_selling_proposition); // must be unique_selling_point
            localStorage.setItem('target_audience', data.target_audience);
            localStorage.setItem('problem_solved', data.problem_solved);
            localStorage.setItem('key_benefits', JSON.stringify(data.key_benefits));
            localStorage.setItem('primary_goal_of_outreach_campaign', data.primary_goal_of_outreach_campaign);
            localStorage.setItem('ideal_client', JSON.stringify(data.ideal_client));
            localStorage.setItem('success_measurement', data.success_measurement);
            localStorage.setItem('suggest_usp', data.suggest_unique_selling_point); // must be suggest_unique_selling_point
            localStorage.setItem('location', data.location);
            localStorage.setItem('industry', JSON.stringify(data.industry));
            
            // Return a Promise to ensure all state updates are complete before moving on
            // return new Promise((resolve) => {
            //     setCampaignName(data.campaign_name);
            //     setCoreService(data.core_service);
            //     setTargetAudience(data.target_audience);
            //     setUniqueSellingProposition(data.unique_selling_proposition);
            //     setProblemSolved(data.problem_solved);
            //     setKeyBenefits(data.key_benefits);
            //     setPrimaryGoalOfOutreachCampaign(data.primary_goal_of_outreach_campaign);
            //     setIdealClient(data.ideal_client);
            //     setSuccessMeasurement(data.success_measurement);
            //     resolve();
            // });
        }

        return data
    } catch (error) {
        console.error('Error extracting campaign info:', error);
    }
  };

  useEffect(() => {
    // Load values from localStorage or set to empty string if not found
    setCoreProduct(localStorage.getItem('core_service') || '');
    setBenefit(localStorage.getItem('benefit') || '');
    setTargetAudience(localStorage.getItem('target_audience') || '');
    setIdealClient(localStorage.getItem('ideal_client_input') || '');
    setUniqueSellingPoint(localStorage.getItem('unique_selling_proposition') || '');
  }, []);
  
  const handleExit = () => {
    navigate('/campinitselect');
  };

  const handleNext = async () => {
    if (!CoreProduct || !Benefit || !TargetAudience || !IdealClient || !UniqueSellingPoint) {
      Swal.fire({
        title: 'Missing Information',
        text: 'Please fill in all fields before proceeding.',
        icon: 'warning',
        confirmButtonText: 'Close',
        confirmButtonColor: "#223F9E"
      });
    } else {
      // Save all fields to localStorage
      localStorage.setItem('core_service', CoreProduct);
      localStorage.setItem('benefit', Benefit);
      localStorage.setItem('target_audience', TargetAudience);
      localStorage.setItem('ideal_client_input', IdealClient);
      localStorage.setItem('unique_selling_proposition', UniqueSellingPoint);
      //
      const parser_input = `Here are user information:
      **Core Service/Product**: ${CoreProduct}
      **Target Audience**: ${TargetAudience}
      **Ideal Client**: ${IdealClient}
      **Key Benefits**: ${Benefit}
      **Unique Selling Proposition**: ${UniqueSellingPoint}`;
      
      // Show a loading alert
      const loadingAlert = Swal.fire({
        title: 'Please wait...',
        text: 'Extracting campaign information.',
        icon: 'info',
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
            Swal.showLoading(); // Show loading spinner
        }
      });
      
      // Call the async function and wait for it to complete
      const data = await extractCampaignInfo(parser_input);
      
      // Close the loading alert
      Swal.close();
      
      navigate('/onboarding-plan');
    }
  };
  
  const IconWithPopup = ({ message }) => {
    const [isHovered, setIsHovered] = useState(false);
  
    return (
      <div 
        className="relative inline-block"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="text-lg cursor-pointer ml-1">
          <FaQuestionCircle /> {/* Replace 🔔 with FaQuestionCircle */}
        </div>
        {isHovered && (
          <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 w-[300px] p-2 bg-gray-800 text-white text-sm rounded shadow-lg z-10">
            {message}
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="w-full bg-gray-100 h-[50px] border rounded-md flex items-center justify-center">
        <div className="w-full flex justify-between items-center pl-8 pr-8">
          <h2 className="text-xl font-bold">Your Campaign</h2>
          <button onClick={handleExit} className="text-gray-500 hover:text-gray-700 text-2xl">&times;</button>
        </div>
      </div>

      <form className="mt-4 mr-8 ml-8 mb-4 space-y-4">
        <div>
          <div className="flex items-center">
            <label htmlFor="core product" className="block text-sm font-medium text-gray-700 mb-1">Core Product:</label>
            <IconWithPopup message={"Please input the name of Core Produce with a short description."}/>
          </div>
          <textarea
            type="text"
            id="core product"
            value={CoreProduct}
            onChange={(e) => setCoreProduct(e.target.value)}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 h-20 resize-none bg-gray-100"
            placeholder='Input the name of core product'
          />
        </div>

        <div>
          <div className="flex items-center">
            <label htmlFor="benefit" className="block text-sm font-medium text-gray-700 mb-1">Benefit:</label>
            <IconWithPopup message={"Please input the benefits of your product."}/>
          </div>
          <textarea
            type="text"
            id="benefit"
            value={Benefit}
            onChange={(e) => setBenefit(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 h-20 resize-none bg-gray-100"
            placeholder='Input benefit of core product'
          />
        </div>

        <div>
          <div className="flex items-center">
            <label htmlFor="target audience" className="block text-sm font-medium text-gray-700 mb-1">Target Audience:</label>
            <IconWithPopup message={"Please input your target audience including your targer industry and the region for your product."}/>
          </div>
          <textarea
            type="text"
            id="target audience"
            value={TargetAudience}
            onChange={(e) => setTargetAudience(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 h-20 resize-none bg-gray-100"
            placeholder='Input the target audience'
          />
        </div>

        <div>
          <div className="flex items-center">
            <label htmlFor="ideal client" className="block text-sm font-medium text-gray-700 mb-1">Ideal Client:</label>
            <IconWithPopup message={"Please input your ideal client that your product is suitable for. The information can including age, working position, economy situation,..."}/>
          </div>
          <textarea
            type="text"
            id="ideal client"
            value={IdealClient}
            onChange={(e) => setIdealClient(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 h-20 resize-none bg-gray-100"
            placeholder='Input the ideal client'
          />
        </div>

        <div>
          <div className="flex items-center">
            <label htmlFor="unique selling point" className="block text-sm font-medium text-gray-700 mb-1">Unique Selling Point:</label>
            <IconWithPopup message={"Please provide your unique selling point of your product which will differentiate your product from competitors in the market."}/>
          </div>
          <textarea
            type="text"
            id="unique selling point"
            value={UniqueSellingPoint}
            onChange={(e) => setUniqueSellingPoint(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 h-20 resize-none bg-gray-100"
            placeholder='Input the unique selling point'
          />
        </div>

      </form>

      <div className="flex justify-center mt-[30px] mb-[30px]">
        <button 
          className="bg-[#223F9E] text-white py-2 px-8 rounded-full hover:bg-blue-700 transition-colors duration-200"
          onClick={handleNext}
        >
          Next
        </button>
      </div>
    </div>
  );
};

const CampManualFill = () => {
  return (
    <div className="bg-gray-100 min-h-screen flex flex-col items-center">
        <HeaderMainPage />
        <div className="w-4/6 h-full items-start rounded-lg shadow-md my-5 mt-[30px] rounded-md bg-white">
          <InformationContainer />
        </div>
      </div>
  );
};

export default CampManualFill;
