import React, { useState, useEffect, useRef, useCallback } from 'react';
import HeaderMainPage from '../../header/HeaderMainPage';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { useNavigate } from 'react-router-dom';
import { useVoiceInput } from './VoiceInput';
import profileImage from '../../assets/profile.png';
import AIimage from '../../assets/image1.png';
import { FaMicrophone, FaMicrophoneSlash } from 'react-icons/fa';
import listeninggif from '../../assets/listening_gif.gif';
import playinggif from '../../assets/playaudio_gif.gif';
import Swal from 'sweetalert2';
import AvatarPlayer from './AvatarStream'; 

const UserAIChatUI = () => {
    const navigate = useNavigate();
    const [messages, setMessages] = useState([]);
    const [messagePairs, setMessagePairs] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [showButtons, setShowButtons] = useState(false);
    const [isStreaming, setIsStreaming] = useState(false);
    const [isBotResponding, setIsBotResponding] = useState(false);
    const [isChatDisabled, setIsChatDisabled] = useState(false);
    const [campaignName, setCampaignName] = useState('');
    const [coreService, setCoreService] = useState('');
    const [targetAudience, setTargetAudience] = useState('');
    const [uniqueSellingProposition, setUniqueSellingProposition] = useState('');
    const [problemSolved, setProblemSolved] = useState('');
    const [keyBenefits, setKeyBenefits] = useState('');
    const [primaryGoalOfOutreachCampaign, setPrimaryGoalOfOutreachCampaign] = useState('');
    const [idealClient, setIdealClient] = useState('');
    const [successMeasurement, setSuccessMeasurement]  = useState('');
    const [location, setLocation] = useState('');
    const [industry, setIndustry] = useState('');
    const [suggest_usp, setSuggestUSP] = useState('');
    const [fullResponse, setFullResponse] = useState('');
    const messagesEndRef = useRef(null);
    const [audioUrl, setAudioUrl] = useState(null);
    const [audioUrlforAvatar, setAudioUrlforAvatar] = useState(null);
    const [error, setError] = useState(null);
    const audioRef = useRef(null);

    // voice mode
    const [isVoiceMode, setIsVoiceMode] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [transcribedText, setTranscribedText] = useState("");
    const [isPlaying, setIsPlaying] = useState(false);
    // const [chathistory, setChatHistory] = useState('')
    const { startListening, stopListening, getTranscript,  isListening, browserSupportsSpeechRecognition, resetTranscript } = useVoiceInput();

    //avatar mode
    const [isAvatarOn, setIsAvatarOn] = useState(false);
    
    
    const roboman_api = process.env.REACT_APP_ROBOMAN_API;

    // useEffect(() => {
    //     console.log("State Update:");
    //     console.log("isListening:", isListening);
    //     console.log("isPlaying:", isPlaying);
    //     console.log("showButtons:", showButtons);
        // console.log("Voice Mode:", isVoiceMode);
    // }, [isListening, isPlaying, showButtons]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const formatList = (list) => {
        if (Array.isArray(list)) {
            return list.map((item) => ` ${item}.`).join('\n');
          }
          return list; // Return as is if not a list
    };

    const getMessageResponse = async (inputMessage) => {
        try {
            const response = await axios({
                method: 'post',
                url: `${roboman_api}/conversation/message`,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': localStorage.getItem('access_token'),
                },
                data: {
                    conversation_id: localStorage.getItem('conversation_id'),
                    message_content: inputMessage,
                    model_id: 1,
                },
            });
    
            // Assuming the response is a string, you can return the response directly.
            return response.data;  // Adjust if the result is inside a nested object, e.g., response.data.result
        } catch (error) {
            console.error("Error fetching message response:", error);
            // throw error;  // Handle the error as needed
        }
    } 

    useEffect( () => {
        const storedCampaignName = localStorage.getItem('campaign_name') || '';
        const storedCoreService = localStorage.getItem('core_service') || '';
        const storedTargetAudience = localStorage.getItem('target_audience') || '';
        const storedUniqueSellingProposition = localStorage.getItem('unique_selling_proposition') || '';
        const storedProblemSolved = localStorage.getItem('problem_solved') || '';
        const storedKeyBenefits = JSON.parse(localStorage.getItem('key_benefits')) || '';
        const storedPrimaryGoalOfOutreachCampaign = localStorage.getItem('primary_goal_of_outreach_campaign') || '';
        const storedIdealClient = JSON.parse(localStorage.getItem('ideal_client')) || '';
        const storedSuccessMeasurement = localStorage.getItem('success_measurement') || '';
        const storedMessagePairs = JSON.parse(localStorage.getItem('messagePairs')) || [];
        
        
        setCampaignName(storedCampaignName);
        setCoreService(storedCoreService);
        setTargetAudience(storedTargetAudience);
        setUniqueSellingProposition(storedUniqueSellingProposition);
        setProblemSolved(storedProblemSolved);
        setKeyBenefits(storedKeyBenefits);
        setPrimaryGoalOfOutreachCampaign(storedPrimaryGoalOfOutreachCampaign);
        setIdealClient(storedIdealClient);
        setSuccessMeasurement(storedSuccessMeasurement);
        setMessagePairs(storedMessagePairs);
        const greeting_prompt = `This is a started message from user. Username is ${localStorage.getItem('nickname')}. Please return a greeting message in the happiness tone, no icon for respect, asking for her situation and provide help. For example: Hi ${localStorage.getItem('nickname')}, how are you today? I hope you always feel fine and happy. How can I help you today?.`

        if (storedCampaignName && storedCoreService && storedTargetAudience &&
            storedUniqueSellingProposition && storedProblemSolved && storedKeyBenefits &&
            storedPrimaryGoalOfOutreachCampaign && storedIdealClient && storedSuccessMeasurement) {
            // const summaryMessage = Here are your summary information: 
            // **Campaign Name**: ${storedCampaignName}
            // **Core Service**: ${storedCoreService}
            // **Target Audience**: ${storedTargetAudience}
            // **Unique Selling Proposition**: ${storedUniqueSellingProposition}
            // **Problem Solved**: ${storedProblemSolved}
            // **Key Benefits**: ${storedKeyBenefits}
            // **Primary Goal of Outreach Campaign**: ${storedPrimaryGoalOfOutreachCampaign}
            // **Ideal Client**: ${storedIdealClient}
            // **Success Measurement**: ${storedSuccessMeasurement};
            // setMessages([{ sender: 'bot', text: summaryMessage }]);
            // setShowButtons(true);
            // setIsChatDisabled(true);
            const fetchhistory = async () => {
                const hisdata = await updatechathistory();
                const chathis = historydatatochat(hisdata);
                setMessages(chathis)

            }
            fetchhistory();
            console.log("All field present")
        } else if (localStorage.getItem("conversation_id") === null) {
            
            stopListening()
            const fetchGreetingText = async () => {
                await getConversationId();
                const greeting_text = await getMessageResponse(greeting_prompt); // await the result

                setMessages([{ sender: 'bot', text: greeting_text }]);  // Set the message once data is fetched
                sum_and_speech(greeting_text, false);  // Text-to-speech conversion
                simulateStreaming(greeting_text);  // Modified streaming
            };


            fetchGreetingText();

        } else {
            const fetchhistory = async () => {
                const hisdata = await updatechathistory();
                const chathis = historydatatochat(hisdata);
                setMessages(chathis)

            }
            fetchhistory();
            // let continue_text = "Let's continue to chat"
            // simulateStreaming(continue_text);
        }
            
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    // This useCallback ensures that the function is only recreated when its dependencies change
    const checkAllFieldsFilled = useCallback(() => {
        return campaignName && coreService && targetAudience && uniqueSellingProposition && problemSolved && keyBenefits && primaryGoalOfOutreachCampaign && idealClient && successMeasurement;
    }, [campaignName, coreService, targetAudience, uniqueSellingProposition, problemSolved, keyBenefits, primaryGoalOfOutreachCampaign, idealClient, successMeasurement]);

    // This useEffect runs whenever the campaign info changes, updating the UI accordingly
    useEffect(() => {
        if (checkAllFieldsFilled()) {
            setShowButtons(true);
            setIsChatDisabled(true);
            const welcomeMessage = `Here are your summary information: 
            **Campaign Name**: ${campaignName}
            **Core Service/Product**: ${coreService}
            **Target Audience**: ${targetAudience}
            **Unique Selling Proposition**: ${uniqueSellingProposition}
            **Problem Solved**: ${problemSolved}
            **Key Benefits**: ${formatList(keyBenefits)}
            **Primary Goal of Outreach Campaign**: ${primaryGoalOfOutreachCampaign}
            **Ideal Client**: ${formatList(idealClient)}
            **Success Measurement**: ${successMeasurement}`;
            setMessages(prevMessages => [...prevMessages, { sender: 'bot', text: welcomeMessage }]);
        } else {
            setShowButtons(false);
            setIsChatDisabled(false);
        }
    }, [checkAllFieldsFilled]);


    const hasAllFields = (message) => {
        const requiredFields = [
            "Campaign Name",
            "Service",
            "Product",
            "Audience",
            "Unique Selling Proposition",
            "Problem Solved",
            "Benefits",
            "Primary Goal",
            "Main Objective",
            "Ideal Client",
            "Success Measurement",
            "Success Metric"
        ];
    
        const threshold = 4; // At least half required fields
        let matchCount = 0;
    
        for (let field of requiredFields) {
            // Make the match case-insensitive and allow partial matching
            const regex = new RegExp(field.replace(/[-/]/g, ".?"), "i"); // Handle slight variations
            if (regex.test(message)) {
                matchCount++;
            }
        }
    
        return matchCount >= threshold;
    };

    // This useEffect handles the extraction of campaign info once the full response is received
    useEffect(() => {
        if (!isStreaming && fullResponse) {
            const lastMessage = messages[messages.length - 1];

            // extractCampaignInfo(lastMessage.text)
            // extractCampaignInfo(messagePairs)
            console.log("check status", hasAllFields(lastMessage.text))
            if (lastMessage && lastMessage.sender === 'bot' && hasAllFields(lastMessage.text)){
                // The .then() ensures that fullResponse is only cleared after extractCampaignInfo completes
                // console.log("extract data")

                extractCampaignInfo(lastMessage.text).then(() => {
                    setFullResponse('');
                    setShowButtons(true);

                    setIsChatDisabled(true);

                    // 
                })
            } else {
                setFullResponse('');
            }
        }
    }, [isStreaming, fullResponse, messages, messagePairs]);



    const getConversationId = async () => {
        try {
            const response = await fetch(`${roboman_api}/conversation/new`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': localStorage.getItem('access_token'),
                },
                body: JSON.stringify({
                    // Add any necessary data for this API call
                }),
            });

            if (!response.ok) {
                console.error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            
            if (data.conversation_id) {
                localStorage.setItem('conversation_id', data.conversation_id);
            }
        } catch (error) {
            console.error('Error getting conversation ID:', error);
        }
    };

    const extractCampaignInfo = async (text) => {
        try {
            const response = await fetch(`${roboman_api}/llm/campaign/parser`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('access_token'),
                },
                body: JSON.stringify({
                    input_str: text
                }),
            });

            if (!response.ok) {
                console.error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            
            if (data) {
                console.log(data);
                localStorage.setItem('campaign_name', data.campaign_name);
                localStorage.setItem('core_service', data.core_service);
                localStorage.setItem('unique_selling_proposition', data.unique_selling_proposition); // must be unique_selling_point
                localStorage.setItem('target_audience', data.target_audience);
                localStorage.setItem('problem_solved', data.problem_solved);
                localStorage.setItem('key_benefits', JSON.stringify(data.key_benefits));
                localStorage.setItem('primary_goal_of_outreach_campaign', data.primary_goal_of_outreach_campaign);
                localStorage.setItem('ideal_client', JSON.stringify(data.ideal_client));
                localStorage.setItem('success_measurement', data.success_measurement);
                localStorage.setItem('suggest_usp', data.suggest_unique_selling_point); // must be suggest_unique_selling_point
                localStorage.setItem('location', data.location);
                localStorage.setItem('industry', JSON.stringify(data.industry));
                
                // Return a Promise to ensure all state updates are complete before moving on
                return new Promise((resolve) => {
                    setCampaignName(data.campaign_name);
                    setCoreService(data.core_service);
                    setTargetAudience(data.target_audience);
                    setUniqueSellingProposition(data.unique_selling_proposition);
                    setProblemSolved(data.problem_solved);
                    setKeyBenefits(data.key_benefits);
                    setPrimaryGoalOfOutreachCampaign(data.primary_goal_of_outreach_campaign);
                    setIdealClient(data.ideal_client);
                    setSuccessMeasurement(data.success_measurement);
                    setLocation(data.location);
                    setIndustry(data.industry);
                    setSuggestUSP(data.suggest_unique_selling_point);
                    resolve();
                });
            }
        } catch (error) {
            console.error('Error extracting campaign info:', error);
        }
    };

    const updatechathistory = async () => {
        const convid = localStorage.getItem('conversation_id')
        try {
            const response = await fetch(`${roboman_api}/conversation/${convid}`, {
              method: 'GET',
              headers: {
                'Accept': 'application/json',
                'Authorization': localStorage.getItem('access_token'),
              },
            });
    
            if (!response.ok) {
                console.error(`HTTP error! status: ${response.status}`);
            }
    
            const data = await response.json();

            // setChatHistory(data);
            return data
          } catch (error) {
            setError(error.message);
          }
    };

    // Function to convert API response into the desired format
    const historydatatochat = (data) => {
        const history = data.flatMap((item, index) => {
            const userMessage = item.message_data.user;
            const botMessage = item.message_data.assistant;
    
            // Skip the first user's message (index === 0)
            const messages = [];
            if (index !== 0) {
                messages.push({ sender: 'user', text: userMessage });
            }
            messages.push({ sender: 'bot', text: botMessage });
    
            return messages;
        });
    
        return history;
    };

    const sum_and_speech = async (assistant, is_summary=true, isAvatarMode=false) => {
        
        // const datachathistory = await updatechathistory();
        // const latestMessage = datachathistory[datachathistory.length - 1];
        // // console.log(latestMessage)

        // if (!latestMessage) {
        //     setError('No messages available.');
        //     return;
        // }

        // const { message_data } = latestMessage;
        // const { assistant } = message_data;

        try {
            const response = await fetch(`${roboman_api}/openai/text-to-speech`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                text_input: assistant,
                is_summary: is_summary,
            }),
        });

        if (!response.ok) {
            console.error(`HTTP error! status: ${response.status}`);
        }

        

        const result = await response.json();
        setAudioUrl(result.image_url);

        // if (isAvatarOn) {
        //     setAudioUrlforAvatar(result.image_url);
        // } else {
        //     setAudioUrl(result.image_url);
        // }
         // Assuming the response contains an `audio_url` field
        // console.log(result.image_url)
        
        // Attempt to play the audio automatically
        if (!isAvatarMode && audioRef.current) {
            audioRef.current.play().catch(error => {
                console.error('Autoplay failed:', error);
            });
        }
        } catch (error) {
            setError(error.message);
        }
    };

    const handleSendMessage = async (voiceMode = false) => {
        const summary = !voiceMode;
        if (inputMessage.trim() === '' || isBotResponding) return;
    
        if (isListening) {
            stopListening();
        }
    
        setIsBotResponding(true);
        setMessages(prev => [...prev, { sender: 'user', text: inputMessage }]);
        resetTranscript();
        setInputMessage(''); // Clear input message after sending
        setShowButtons(false);
        setIsStreaming(true);
        setFullResponse('');
        
         // Add a waiting message from the bot to indicate it's processing
        setMessages(prev => [...prev, { sender: 'bot', text: 'Loading...' }]);

        // If this is the first message, get the conversation ID
        
        // if (messages.length === 1 && localStorage.getItem("messagePairs") == null) {
        // }

        // old code with streaming
        //     try {
        //         await axios({
        //             method: 'post',
        //             url: `${roboman_api}/conversation/message`,
        //             headers: {
        //                 'Content-Type': 'application/json',
        //                 'Accept': 'application/json',
        //                 'Authorization': localStorage.getItem('access_token'),
        //             },
        //             data: {
        //                 conversation_id: localStorage.getItem('conversation_id'),
        //                 message_content: inputMessage,
        //                 model_id: 1,
        //             },
        //             responseType: 'stream',
        //             onDownloadProgress: (progressEvent) => {
        //                 const { response } = progressEvent.event.currentTarget;
        //                 // console.log(response)
                        
        //                 accumulatedResponse = response;
                        
        //                 setMessages(prev => {
        //                     const updatedMessages = [...prev];
        //                     if (updatedMessages[updatedMessages.length - 1].sender === 'bot') {
        //                         updatedMessages[updatedMessages.length - 1].text = response;
        //                     } else {
        //                         updatedMessages.push({ sender: 'bot', text: response });
        //                     }
        //                     return updatedMessages;
        //                 });
        //             }
        //         });
    
        try {
            let fullResponse = '';
            
            // Fetch the full response from the server
            const response = await axios({
                method: 'post',
                url: `${roboman_api}/conversation/message`,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': localStorage.getItem('access_token'),
                },
                data: {
                    conversation_id: localStorage.getItem('conversation_id'),
                    message_content: inputMessage,
                    model_id: 1,
                },
            });
            // console.log("response: ", response)
            // console.log("response data: ", response.data)
            fullResponse = response.data; // Adjust according to your API response structure
            // console.log("message: ", fullResponse)
            setFullResponse(fullResponse);
    
            // Start sum_and_speech
            console.log('Start summarizing and speaking');
            console.log("avatar on mode:", isAvatarOn)
            await sum_and_speech(fullResponse, summary, isAvatarOn);
    
            // Simulate streaming text while `sum_and_speech` is running
            simulateStreaming(fullResponse);
    
            setMessagePairs(prev => {
                const updatedPairs = Array.isArray(prev) ? [...prev, [inputMessage, fullResponse]] : [[inputMessage, fullResponse]];
                localStorage.setItem('messagePairs', JSON.stringify(updatedPairs));
                return updatedPairs;
            });
    
            if (checkAllFieldsFilled()) {
                setShowButtons(true);
                setIsChatDisabled(true);
            }

            // Re-enable listening only if Voice Mode is true
            // if (voiceMode && !isPlaying) {
            //     startListening(); // Ensure to start listening if Voice Mode is true
            // }
        } catch (error) {
            console.log(error)
            console.error('Error:', error);
            let errorMessage = "Sorry, I encountered an error. Please try again.";
            if (error.response) {
                errorMessage = `Server error. Please try again later.`;
            } else if (error.request) {
                errorMessage = "No response from the server. Please check your internet connection.";
            } else {
                errorMessage = "An unexpected error occurred. Please try again.";
            }
            setMessages(prev => [...prev, { sender: 'bot', text: errorMessage }]);
        } finally {
            setIsBotResponding(false);
        }
    };
    
    // Function to simulate streaming text
    const simulateStreaming = (fullText) => {
        if (!fullText || typeof fullText !== 'string') {
            console.error('simulateStreaming received invalid input:', fullText);
            return;
        }
    
        const words = fullText.split(' '); // Safely split the string
        let currentText = '';
    
        words.forEach((word, index) => {
            setTimeout(() => {
                currentText += `${word} `;
                setMessages(prev => {
                    const updatedMessages = [...prev];
                    if (updatedMessages[updatedMessages.length - 1]?.sender === 'bot') {
                        updatedMessages[updatedMessages.length - 1].text = currentText.trim();
                    } else {
                        updatedMessages.push({ sender: 'bot', text: currentText.trim() });
                    }
                    return updatedMessages;
                });
            }, index * 100); // Adjust delay as needed for smooth streaming
        });
    
        // Stop streaming when the text is fully displayed
        setTimeout(() => {
            setIsStreaming(false);
        }, words.length * 100);
    };
    
    const handleConfirm = () => {
        console.log('Confirmed and proceeding');
        // setShowButtons(false);
        navigate('/onboarding-plan')
    };

    const handleAddDetails = () => {
        console.log('Adding more details');
        setShowButtons(false);
        setIsChatDisabled(false);
    };

    // useEffect(() => {I got an
    //     const transcript = getTranscript();
    //     if (transcript) {
    //         setInputMessage(transcript);
    //     }
    // }, [getTranscript]);

    // Update inputMessage whenever transcript changes
    // useEffect(() => {
    //     const transcript = getTranscript()
    //     if (transcript && isListening) {
    //         setInputMessage(transcript); 
    //         setTranscribedText(transcript);
    //     }
    // }, [getTranscript, isListening]);

    const handleVoiceInput = (voiceMode = false) => {
        if (isListening) {
            stopListening();
            resetTranscript(); // Clear the transcript after stopping
            if (voiceMode === true) {
                handleSendMessage(true); // Send the message in VoiceMode UI
            }
        } else {
            startListening();
        }
    };

    const messageBubbleStyle = {
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
        width: 'fit-content',
    };

    

    const handleKeyDown = (e) => {
        // Check if Enter key is pressed
        if (e.key === 'Enter') {
          // If Shift key is held down, insert a new line
          if (e.shiftKey) {
            return; // Allow new line to be added
          } else {
            // Otherwise, send the message
            e.preventDefault(); // Prevent default behavior (e.g., form submission or new line)
            handleSendMessage();
          }
        }
    };

    // add here
    const [transcriptTimeout, setTranscriptTimeout] = useState(null);
    const [previousTranscript, setPreviousTranscript] = useState(""); // Track previous transcript

    const handleVoiceMode = () => {
        resetTranscript();
        // setIsAvatarOn(true);
        setIsVoiceMode(true);
        startListening(); // Automatically start listening when Voice Mode is activated
    };
    
    const handleCloseVoiceMode = () => {
        setIsVoiceMode(false);
        // setIsAvatarOn(false);
        stopListening(); // Automatically stop listening when Voice Mode is closed
    };

    // Function to reset the timeout for auto-sending the message
    const resetAutoSendTimeout = () => {
        if (transcriptTimeout) {
            clearTimeout(transcriptTimeout); // Clear the existing timeout
        }
    
        const timeout = setTimeout(async () => { // Use async for the callback
            if (transcribedText && transcribedText.trim() !== "" && isVoiceMode) { // Only send if there's actual text
                try {
                    await handleSendMessage(true); // Wait for handleSendMessage to complete
                } catch (error) {
                    console.error("Error in handleSendMessage:", error);
                }
                // Add additional steps here if needed, after handleSendMessage completes
            }
        }, 1000);
    
        setTranscriptTimeout(timeout);
    };

    // Monitor changes in the transcript and reset timeout
    useEffect(() => {
        const transcript = getTranscript();

        // Update inputMessage only if transcript is different from previous
        if (transcript !== previousTranscript && isListening) {
            setInputMessage(transcript); // Update the input message
            setTranscribedText(transcript); // Store transcribed text for auto-send logic
            setPreviousTranscript(transcript); // Update the previous transcript
            resetAutoSendTimeout(); // Reset timeout whenever the transcript changes
            // if (isVoiceMode) {
            //     handleSendMessage(true);
            // }
        }
    }, [getTranscript, isListening, previousTranscript]);
    
    useEffect(() => {
        // Start listening if VoiceMode is enabled, playback is not happening, and we're not already listening
        if (isVoiceMode && !isPlaying && !isListening) {
            startListening();
        }
    }, [isVoiceMode, isPlaying, isListening]);

    // const updateTranscribedText = (text) => {
    //     setTranscribedText(text);
    // };

     // Handle Avatar toggle

    return (
        <div className="bg-white min-h-screen flex flex-col items-center">
            <HeaderMainPage />
            <div className="w-full h-20 flex justify-center items-center bg-white border-b-4 border-gray-300">
                <h1 className="text-blue-800 text-sm font-bold">HELP US CUSTOMIZE YOUR EXPERIENCE WITH OUR ASSISTANT!</h1>
            </div>
            <div className='h-full w-full flex justify-center'>
                <div className="flex flex-col item-start justify-center w-full md:w-[70%] h-[700px] bg-white-100">
                    <div className="flex-1 overflow-y-auto p-4 bg-white scroll-smooth">
                        {messages.map((message, index) => (
                            <div key={index} className={`flex ${message.sender === 'user' ? 'justify-end' : 'justify-start'} mb-4`}>
                                <div className={`flex ${message.sender === 'user' ? 'flex-row-reverse' : 'flex-row'} items-end mb-4`}>
                                    <div className={`w-10 h-10 rounded-full flex-shrink-0 overflow-hidden ${message.sender === 'user' ? 'bg-white-100' : 'bg-blue-500'}`}>
                                        <img 
                                            src={message.sender === 'user' ? profileImage : AIimage} 
                                            alt={message.sender === 'user' ? 'User Avatar' : 'AI Avatar'}
                                            className="w-full h-full object-cover"
                                        />
                                    </div>
                                    <div 
                                        className={`mx-4 py-2 px-4 max-w-[900px] rounded-lg ${message.sender === 'user' ? 'bg-[#edf1ff] text-black' : 'bg-gray-200'}`}
                                        style={messageBubbleStyle}
                                    >
                                        {message.sender === 'user' ? (
                                            message.text
                                        ) : (
                                            <ReactMarkdown>{message.text}</ReactMarkdown>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div ref={messagesEndRef} />
                    </div>
                    
                    {audioUrl && (
                        <>
                        <audio
                            src={audioUrl}
                            ref={audioRef}
                            autoPlay
                            style={{ display: 'none' }}
                            onPlay={() => setIsPlaying(true)}
                            onEnded={() => setIsPlaying(false)}
                            onError={() => {
                            setError('Failed to play audio');
                            setIsPlaying(false);
                            }}
                        />
                        {isPlaying && (
                            <div className="mt-2 ml-10 bg-white text-green-500">
                                Talking...
                            </div>
                        )}
                        </>
                    )}

                    {/* Voice Mode Overlay */}
                    {/* {isAvatarOn && (  */}

                            {/* Placeholder with dynamic content */}
                            {/* <div className="flex justify-center items-center mb-4 flex-1">
                                <div className="w-[512px] h-[512px] bg-gray-200 rounded-full flex items-center justify-center overflow-hidden">
                                    <AvatarPlayer
                                        audiourl={audioUrlforAvatar}
                                        className="w-full h-full object-cover"
                                    />
                                </div>
                            </div> */}
                    {isVoiceMode && (
                        <div className="fixed inset-0 bg-white flex flex-col items-center justify-between z-50">
                            {/* Placeholder with dynamic content */}
                            {/* <div className="flex justify-center items-center mb-4 flex-1">
                                {isAvatarOn ? (
                                    <div className="w-[512px] h-[512px] bg-gray-200 rounded-full flex items-center justify-center overflow-hidden">
                                    <AvatarPlayer 
                                        audiourl={audioUrl} 
                                        className="w-full h-full object-cover"
                                        // You might want to pass additional props like setIsPlaying if needed
                                    />
                                    </div>
                                ) : (
                                    // Original placeholder logic
                                    <div className="flex justify-center items-center mb-4 flex-1">
                                        {isListening ? (
                                            // Show the listening GIF when isListening is true
                                            <div className="w-60 h-60 rounded-full flex items-center justify-center">
                                                <img src={listeninggif} alt="Listening" className="w-60 h-60 object-cover rounded-full" />
                                            </div>
                                        ) : isPlaying ? (
                                            // Show the playing GIF when isPlaying is true
                                            <div className="w-60 h-60 rounded-full flex items-center justify-center">
                                                <img src={playinggif} alt="Playing Audio" className="w-60 h-60 object-cover rounded-full" />
                                            </div>
                                        ) : (
                                            // Default placeholder
                                            <div className="w-60 h-60 rounded-full flex items-center justify-center">
                                                <img src={playinggif} alt="Playing Audio" className="w-60 h-60 object-cover rounded-full" />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div> */}

                            {/* Placeholder with dynamic content */}
                            <div className="flex justify-center items-center mb-4 flex-1">
                                <div className="flex justify-center items-center mb-4 flex-1">
                                    {isListening ? (
                                        // Show the listening GIF when isListening is true
                                        <div className="w-60 h-60 rounded-full flex items-center justify-center">
                                            <img src={listeninggif} alt="Listening" className="w-60 h-60 object-cover rounded-full" />
                                        </div>
                                    ) : isPlaying ? (
                                        // Show the playing GIF when isPlaying is true
                                        <div className="w-60 h-60 rounded-full flex items-center justify-center">
                                            <img src={playinggif} alt="Playing Audio" className="w-60 h-60 object-cover rounded-full" />
                                        </div>
                                    ) : (
                                        // Default placeholder
                                        <div className="w-60 h-60 rounded-full flex items-center justify-center">
                                            <img src={playinggif} alt="Playing Audio" className="w-60 h-60 object-cover rounded-full" />
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Avatar Toggle Button - Placed between Placeholder and Text Area */}
                            {/* <div className="w-full flex justify-center mb-4">
                                <button 
                                    onClick={handleAvatarToggle}
                                    className={`rounded-full px-4 py-2 text-white font-semibold 
                                        ${isAvatarOn ? 'bg-red-500' : 'bg-green-500'}`}
                                >
                                    {isAvatarOn ? 'Turn off Avatar' : 'Turn on Avatar'}
                                </button>
                            </div> */}

                            {/* Text area to display what the user said */}
                            <div className="w-[90%] p-2 bg-gray-100 rounded-md text-center mb-4">
                                <p className="text-gray-800 text-sm">
                                    {isPlaying ? "AI is Speaking" : (transcribedText || "Listening...")}
                                </p>
                            </div>

                            {/* Buttons at the bottom */}
                            <div className="flex justify-center space-x-4 mb-6">
                                <button
                                    className={`rounded-full p-4 text-white ${isListening ? 'bg-red-500' : 'bg-gray-500'}`}
                                    disabled={isListening || isPlaying || showButtons} // Disable button while listening, playing, or showButtons is true
                                >
                                    {isListening ? (
                                        <FaMicrophone size={24} /> // Show microphone icon when listening
                                    ) : isPlaying ? (
                                        <FaMicrophoneSlash size={24} /> // Show microphone slash icon when playing
                                    ) : (
                                        <FaMicrophoneSlash size={24} /> // Default to microphone slash icon
                                    )}
                                </button>
                                <button
                                    onClick={handleCloseVoiceMode}
                                    className="bg-red-500 text-white rounded-full p-4"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6m0 12L6 6" />
                                    </svg>
                                </button>
                            </div>

                            {/* Additional buttons (appear below with transition) */}
                            {showButtons && (
                                <div
                                    className="flex justify-center space-x-4 p-4 bg-white opacity-0 translate-y-4 transition-all duration-500 ease-in-out"
                                    style={{ opacity: 1, transform: "translateY(0)" }}
                                >
                                    <button
                                        onClick={handleConfirm}
                                        className="bg-[#223F9E] text-white px-4 py-2 rounded-full font-semibold text-sm"
                                    >
                                        Confirm and Proceed
                                    </button>
                                    <button
                                        onClick={handleAddDetails}
                                        className="bg-gray-500 text-white px-4 py-2 rounded-full font-semibold text-sm"
                                    >
                                        Continue Speaking
                                    </button>
                                </div>
                            )}
                        </div>
                    )}



                    {showButtons && (
                        <div className="flex justify-center space-x-4 p-4 bg-white">
                            <button onClick={handleConfirm} className="bg-[#223F9E] text-white px-4 py-2 rounded-full font-semibold text-sm">
                                Confirm and Proceed
                            </button>
                            <button onClick={handleAddDetails} className="bg-gray-500 text-white px-4 py-2 rounded-full font-semibold text-sm">
                                Add More Details
                            </button>
                        </div>
                    )}

                    {/* test here */}
                    <div className="p-4 bg-white">
                    {/* Send Message Box */}
                    <div className="flex items-center bg-gray-100 rounded-xl p-2 ml-10 mr-10 max-h-[300px]">
                        <textarea
                            value={inputMessage}
                            onChange={(e) => setInputMessage(e.target.value)}
                            onKeyDown={(e) => handleKeyDown(e)}
                            className="flex-1 bg-transparent outline-none pl-5 resize-none h-50 overflow-y-auto flex justify-center"
                            placeholder="Type your message..."
                            disabled={isBotResponding || isChatDisabled}
                            rows={3}
                        />
                        {browserSupportsSpeechRecognition && (
                            <button
                                onClick={() => handleVoiceInput(isVoiceMode)}
                                className={`mr-2 ${isBotResponding || isChatDisabled ? 'bg-gray-400' : isListening ? 'bg-red-500' : 'bg-[#223F9E]'} text-white rounded-full p-2`}
                                disabled={isBotResponding || isChatDisabled}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4 w-4"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z"
                                    />
                                </svg>
                            </button>
                        )}
                        <button
                            onClick={() => handleSendMessage(isVoiceMode)}
                            className={`mr-2 ${isBotResponding || isChatDisabled ? 'bg-gray-400' : 'bg-[#223F9E]'} text-white rounded-full p-2`}
                            disabled={isBotResponding || isChatDisabled}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={4}
                                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                                />
                            </svg>
                        </button>
                    </div>

                    {/* USE VOICE MODE Button - Positioned Below */}
                    {browserSupportsSpeechRecognition && (
                        <div className="flex justify-center mt-4">
                            <button
                                onClick={handleVoiceMode}
                                className={`w-[200px] py-2 text-center font-bold rounded-full ${
                                    isBotResponding || isChatDisabled ? 'bg-gray-400' : 'bg-green-500'
                                } text-white`}
                                disabled={isBotResponding || isChatDisabled}
                            >
                                USE VOICE MODE
                            </button>
                        </div>
                    )}
                </div>

                </div>
            </div>
        </div>
    );
};

export default UserAIChatUI;
