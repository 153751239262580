import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import SharedAuthUI from './SharedAuthUI';
import '../../../src/App.css';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const roboman_api = process.env.REACT_APP_ROBOMAN_API;


    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleForgotPassword = async () => {
        try {
            const response = await fetch(`${roboman_api}/forgot-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                }),
            });

            if (response.ok) {
                const data = await response.json();
                setMessage(data.message);
                setError('');
                // Optionally navigate to another page or inform the user to check their email
            } else {
                const errorData = await response.json();
                setError(errorData.detail);
                setMessage('');
            }
        } catch (error) {
            console.error('Error:', error);
            setError('An error occurred. Please try again.');
            setMessage('');
        }
    };

    return (
        <SharedAuthUI>
            <div className="w-1/2 h-[300px] p-10 mt-8">
                <h2 className="font-bold text-2xl mb-6">Forgot Password</h2>
                <form onSubmit={(e) => { e.preventDefault(); handleForgotPassword(); }} className="space-y-6">
                    <input
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={handleEmailChange}
                        className="w-full px-4 py-3 mt-[20px] rounded-md bg-gray-100 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required
                    />
                    {message && <p className="text-green-500 text-sm">{message}</p>}
                    {error && <p className="text-red-500 text-sm">{error}</p>}
                    <button type="submit" className="w-full bg-blue-600 text-white py-3 rounded-md hover:bg-blue-700 transition duration-300">Send Reset Link</button>
                </form>
                        {/* <button className="w-full mt-4 bg-white text-blue-600 py-3 border border-blue-600 rounded-md hover:bg-blue-50 transition duration-300" onClick={() => navigate('/login')}>Cancel</button> */}
                <p className="text-center text-sm text-gray-600 mt-6">
                    Remembered your password? <Link to="/login" className="text-blue-600 hover:underline">Sign In</Link>
                </p>
            </div>
        </SharedAuthUI>
    );
}

export default ForgotPassword;
