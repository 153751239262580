import React, { useState, useRef, useEffect } from 'react';
import Hls from 'hls.js';
import Swal from 'sweetalert2';
import sayNothingVideo from '../../assets/saynothing.mp4';

const AvatarPlayer = ({ audiourl }) => {
    const localVideoRef = useRef(null);
    const hlsVideoRef = useRef(null);
    const [isHlsPlaying, setIsHlsPlaying] = useState(false);
    const [hlsUrl, setHlsUrl] = useState(null);

    // Function to remove https:// from URL
    const removeHttps = (url) => {
        return url.replace(/^https?:\/\//, '');
    };

    // Function to convert audio data to base64
    const fetchAndConvertToBase64 = async (url) => {
        try {
            const response = await fetch(`${url}`, { method: 'GET',headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache'
            }});
            if (!response.ok) {
                Swal.fire({
                    icon: 'error',
                    title: 'Fetch Error',
                    text: `HTTP error! status: ${response.status}`,
                    confirmButtonText: 'OK'
                });
                return null;
            }
            const arrayBuffer = await response.arrayBuffer();
            const base64 = btoa(
                new Uint8Array(arrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), '')
            );
            console.log("audio base64:", base64)
            return base64;
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Conversion Error',
                text: 'Error fetching or converting audio file',
                confirmButtonText: 'OK'
            });
            return null;
        }
    };

    // Function to call Simli API and get HLS URL
    const simliapiFetchAPI = async (audioBase64Data) => {
        const url = "https://api.simli.ai/audioToVideoStream";
        const data = {
            simliAPIKey: "",
            faceId: "",
            audioBase64: audioBase64Data,
            audioFormat: "mp3",
            audioSampleRate: 24000,
            audioChannelCount: 1,
            videoStartingFrame: 0,
        };

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                Swal.fire({
                    icon: 'error',
                    title: 'Simli API Error',
                    text: `HTTP error! status: ${response.status}`,
                    confirmButtonText: 'OK'
                });
                return null;
            }

            const result = await response.json();
            return result.hls_url;
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Simli API Error',
                text: error.message,
                confirmButtonText: 'OK'
            });
            return null;
        }
    };

    useEffect(() => {
        const processAudio = async () => {
            // If no audio URL is provided, do nothing special
            if (!audiourl) return;

            // Remove https:// from the URL
            // const cleanedUrl = removeHttps(audiourl);

            // Convert audio to base64
            const base64Audio = await fetchAndConvertToBase64(audiourl);
            if (!base64Audio) return;

            // Call Simli API to get HLS URL
            const fetchedHlsUrl = await simliapiFetchAPI(base64Audio);
            if (fetchedHlsUrl) {
                setHlsUrl(fetchedHlsUrl);
            }
        };

        processAudio();
    }, [audiourl]);

    useEffect(() => {
        const localVideo = localVideoRef.current;
        const hlsVideo = hlsVideoRef.current;

        if (localVideo && hlsVideo) {
            // Always start with local video looping
            localVideo.loop = true;
            localVideo.play();

            // If HLS URL is available, set up HLS playback
            if (hlsUrl) {
                let hls;
                if (hlsVideo.canPlayType('application/vnd.apple.mpegurl')) {
                    // Native HLS support
                    hlsVideo.src = hlsUrl;
                } else if (Hls.isSupported()) {
                    // Use hls.js for non-native support
                    hls = new Hls();
                    hls.loadSource(hlsUrl);
                    hls.attachMedia(hlsVideo);

                    hls.on(Hls.Events.MANIFEST_PARSED, () => {
                        // When HLS manifest is ready
                        localVideo.pause();
                        hlsVideo.play();
                        setIsHlsPlaying(true);
                    });

                    hls.on(Hls.Events.ERROR, (event, data) => {
                        Swal.fire({
                            icon: 'error',
                            title: 'HLS Error',
                            text: 'Error loading HLS video',
                            confirmButtonText: 'OK'
                        });
                        // Fallback to local video if HLS fails
                        hlsVideo.pause();
                        localVideo.play();
                        setIsHlsPlaying(false);
                    });
                }

                // Handle HLS video end
                hlsVideo.addEventListener('ended', () => {
                    hlsVideo.pause();
                    localVideo.play();
                    setIsHlsPlaying(false);
                });

                // Cleanup
                return () => {
                    if (hls) {
                        hls.destroy();
                    }
                };
            }
        }
    }, [hlsUrl]);

    return (
        <div className="flex items-center justify-center h-screen">
            <div 
                className="relative overflow-hidden"
                style={{ 
                    width: '512px', 
                    height: '512px', 
                    backgroundColor: 'white' 
                }}
            >
                {/* Local Video (saynothing.mp4) */}
                <video
                    ref={localVideoRef}
                    src={sayNothingVideo}
                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 min-w-full min-h-full object-cover"
                    style={{ 
                        display: isHlsPlaying ? 'none' : 'block',
                        zIndex: 10,
                        width: '512px',
                        height: '512px'
                    }}
                    muted
                />
        
                {/* HLS Video */}
                <video
                    ref={hlsVideoRef}
                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 min-w-full min-h-full object-cover"
                    style={{ 
                        display: isHlsPlaying ? 'block' : 'none',
                        zIndex: 20,
                        width: '512px',
                        height: '512px'
                    }}
                    controls
                />
            </div>
        </div>
    );
}

export default AvatarPlayer;