// AuthContext.js
import React, { createContext, useContext, useState, useEffect  } from 'react';
import axios from 'axios';

// Create a context for authentication
const AuthContext = createContext();

// Custom hook to use the AuthContext
export const useAuth = () => useContext(AuthContext);

// AuthProvider component to wrap the app and provide auth state
export const AuthProvider = ({ children }) => {
  const roboman_api = process.env.REACT_APP_ROBOMAN_API;
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    // Initialize state from localStorage
    return localStorage.getItem('isLoggedIn') === 'true';
  });

  const [loading, setLoading] = useState(true);

  const login = () => {
    setIsLoggedIn(true);
    localStorage.setItem('isLoggedIn', 'true');
  };

  const logout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('access_token');
    // Clear all other stored data (if needed)
    localStorage.clear(); // This will clear all data in localStorage
  };

  const checkTokenValidity = async () => {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      logout(); // No token found, logout user
      return;
    }

    try {
      // Make the API call to validate the token
      const response = await axios.get(`${roboman_api}/users/me`, {
        headers: {
          'Authorization': accessToken,
          'accept': 'application/json',
        },
      });

      // If the response is successful, stay logged in
      if (response.status === 200) {
        setIsLoggedIn(true); // Successful login
      } else if (response.status === 401 || response.status === 403) {
        setIsLoggedIn(false); // Unauthorized or Forbidden, log out or deny access
      }
    } catch (error) {
      console.error('Token expired or invalid:', error);
      logout(); // If token is invalid or expired, logout user
    } finally {
      setLoading(false); // Stop loading after the check
    }
  };

  useEffect(() => {
    // Check the token validity when the app first loads
    checkTokenValidity();
  }, []);

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};