// Import SweetAlert2 (make sure SweetAlert2 is installed in your project)
import Swal from "sweetalert2";


// Fetch campaign companies function
export async function fetchCampaignCompanies(campaignId, page, pageSize, authToken) {
  // Show SweetAlert loading modal
  Swal.fire({
    title: "Loading...",
    text: "Please wait while we fetch the data.",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    },
  });
  const roboman_api = process.env.REACT_APP_ROBOMAN_API;

  // API endpoint
  const url = `${roboman_api}/campaign/${campaignId}/companies/${page}/${pageSize}`;
  // console.log("Request URL:", url);
  try {
    // Fetch data
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: authToken,
      },
    });

    // Check response status
    if (!response.ok) {
      console.error(`HTTP error! status: ${response.status}`);
      Swal.close();
      return null;
    }

    // Parse the JSON response
    const data = await response.json();
    console.log("Successully take data")
    // Close the loading modal
    Swal.close();

    // Return the fetched data
    return data;
  } catch (error) {
    // Handle errors and show an alert
    Swal.fire({
      icon: "error",
      title: "Error!",
      text: `An error occurred: ${error.message}`,
    });

    console.error("Error fetching campaign companies:", error);
    return null;
  }
}
