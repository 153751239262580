import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import Swal from 'sweetalert2';

// Component to protect routes
const ProtectedRoute = ({ children }) => {
  const { isLoggedIn } = useAuth();
  const [loading, setLoading] = useState(true);
  const accessToken = localStorage.getItem('access_token');

  useEffect(() => {
    // Show SweetAlert with confirmation button if not logged in and no access token
    if (!accessToken && !isLoggedIn) {
      Swal.fire({
        title: 'Not Logged In',
        text: 'Please login to continue.',
        icon: 'warning',
        allowOutsideClick: false,
        confirmButtonText: 'Login',
      }).then(() => {
        // After confirming, redirect to the login page
        window.location.href = '/login'; // Alternatively, use <Navigate to="/login" />
      });
    } else {
      setLoading(false); // If logged in or has access token, stop loading
    }
  }, [accessToken, isLoggedIn]); // Run effect when accessToken or isLoggedIn changes

  if (loading) {
    return null; // Prevent rendering the protected route while checking the login state
  }

  return isLoggedIn || accessToken ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;
